import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { type LiveSocketObject } from '@syspons/monitoring-api-common';
import Parse from 'parse';

import { Observable, ReplaySubject } from 'rxjs';

/**
 Service to handle live queries of objects in S_LiveSockets class.
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export default class ParseLiveSocketService {
  performQuery(id?: string, name?: string | undefined): Observable<Parse.Object<LiveSocketObject<any>>> {
    const socketObject$: ReplaySubject<Parse.Object<LiveSocketObject<any>>> = new ReplaySubject<
      Parse.Object<LiveSocketObject<any>>
    >();
    const query = new Parse.Query<Parse.Object<LiveSocketObject<any>>>('S_LiveSockets');
    let valid = false;
    if (id) {
      query.equalTo('objectId', id);
      valid = true;
    } else if (name) {
      query.equalTo('name', name);
      valid = true;
    }
    if (valid) {
      query.first().then(object => {
        if (object) {
          socketObject$.next(object as Parse.Object<LiveSocketObject<any>>);
        }
      }, socketObject$.error);
      query.subscribe().then(subscription => {
        subscription.on('update', object => {
          if (object) {
            socketObject$.next(object as Parse.Object<LiveSocketObject<any>>);
          }
        });
      }, socketObject$.error);
    } else {
      setTimeout(() => {
        socketObject$.error(`Cannot query socket object. ObjectId / name are missing`);
      }, 250);
    }
    return socketObject$;
  }
}
