import { Injector, NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgsOptionsService } from '@syspons/ngs-storage';
import ParseSystemFactory from './parseSystem.factory';
import ParseSystemService from './parseSystem.service';
import { NgsDialogService } from '@syspons/ngs-dialog';
import { NgsSnackbarService } from '@syspons/ngs-snackbar';
import { NgsLoaderService } from '@syspons/ngs-loader';
import ParseLiveSocketService from '../parseLiveSocket/parseLiveSocket.service';

@NgModule({
  providers: [
    {
      provide: ParseSystemService,
      useFactory: ParseSystemFactory,
      deps: [
        TranslateService,
        NgsOptionsService,
        NgsDialogService,
        NgsSnackbarService,
        NgsLoaderService,
        ParseLiveSocketService,
        Injector,
      ],
    },
  ],
})
export class ParseSystemModule {
  constructor(s: ParseSystemService) {
    //
  }
}
