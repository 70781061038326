import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, type OperatorFunction, catchError, from, of, switchMap } from 'rxjs';
import Parse from 'parse';
import _ from 'lodash';

import {
  type NgsSortableProperties,
  type NgsSearchableProperties,
  type NgsFilterableProperties,
  NgsFilterType,
  type NgsFilterableProperty,
} from '@syspons/ngs-filter';
import type {
  INgsEntityController,
  NgsOneOrMultiple,
  PaginationState,
  PaginationEvent,
  NgsSortOrderDirection,
} from '@syspons/models';
import type { NgsEntityTableColumns } from '@syspons/ngs-entity';
import type { NgsHttpRouteParameters, NgsId, NgsIds, NgsEntity, NgsEntities } from '@syspons/ngs-common/models';
import { NgsSnackbarService } from '@syspons/ngs-snackbar';
import { NgsLoaderService } from '@syspons/ngs-loader';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NgsOptionsService } from '@syspons/ngs-storage';
import { LOCALE_OPTION_KEY } from '@syspons/ngs-locale';
import { NgsDialogService } from '@syspons/ngs-dialog';
import {
  type ClassAttribute,
  ClassAttributeType,
  type ClassConfig,
  type ClassesConfig,
  MonitoringCloudFunctions,
  setObjectACLPermissions,
  SetParseEntityValue,
  type CloudFunctionResult,
  CloudFunctionResultClientActions,
  type ClassRelationSchema,
} from '@syspons/monitoring-api-common';
import { PageEvent } from '@angular/material/paginator';

import { ParseCloudController } from '../parseCloud/parseCloud.Controller';
import ParseSystemService, { type KeysWithValue } from '../parseSystem/parseSystem.service';
import { type ParseEntities, ParseEntity, ParseEntityFactory } from './parseEntity.model';
import ParseQueryService from '../parseQuery/parseQueryService';

interface RelationQuery {
  key: keyof ParseEntity;
  results: ParseEntities;
}

@UntilDestroy()
@Injectable()
export class ParseEntityController implements INgsEntityController<ParseEntity> {
  className: string;
  objId: string;

  entities$: BehaviorSubject<ParseEntities>;
  paginationState$: BehaviorSubject<PaginationState>;
  entityAttributes$: BehaviorSubject<string[]>;
  searchableProperties: NgsSearchableProperties;
  sortableProperties: NgsSortableProperties;
  filterableProperties: NgsFilterableProperties;
  columns: NgsEntityTableColumns;

  lang: string;
  pageSize = 50;

  constructor(
    private systemService: ParseSystemService,
    private parseEntityFactory: ParseEntityFactory,
    private parseCloudController: ParseCloudController,
    private parseQueryService: ParseQueryService,
    private ngsOptionsService: NgsOptionsService,
    private ngsLoaderService: NgsLoaderService,
    private ngsDialogService: NgsDialogService,
    private ngsSnackbarService: NgsSnackbarService,
  ) {
    this.entities$ = new BehaviorSubject<ParseEntities>([]);
    this.paginationState$ = new BehaviorSubject<PaginationState>({
      pageIndex: 0,
      length: 0,
      pageSize: this.pageSize,
    });
    this.entityAttributes$ = new BehaviorSubject<string[]>([]);

    this.ngsOptionsService
      .getObservable<string>(LOCALE_OPTION_KEY)
      .pipe(untilDestroyed(this))
      .subscribe(lang => {
        this.lang = lang;
      });
  }
  /**
   * Used by the paginator to count totals and current index
   */
  performCount(className: string, pageEvent?: PageEvent) {
    const index = pageEvent ? pageEvent.pageIndex : 0;

    new Parse.Query(className).count().then(
      count => {
        this.paginationState$.next({
          pageIndex: index,
          length: count,
          pageSize: this.paginationState$.value.pageSize,
        });
      },
      error => console.log(error),
    );
  }
  /**
   * Make a query and set className
   */
  performQuery = (
    className: string,
    getAllEntities?: boolean,
    pageEvent?: PageEvent,
    orderBy?: string,
    orderDirection?: NgsSortOrderDirection,
  ) => {
    this.ngsLoaderService.isLoading$.next(true);

    // Pagination
    this.className = className;
    if (!getAllEntities) {
      this.performCount(className, pageEvent);
    }
    const limit = pageEvent ? pageEvent.pageSize : this.pageSize;
    const index = pageEvent ? pageEvent.pageIndex : 0;
    const skipIndex = index * limit;

    orderBy =
      orderBy && orderBy !== ''
        ? orderBy
        : // Use default
          this.systemService.systemObj.get('classes_config')[className]!.classConfig.orderBy;
    orderDirection =
      orderDirection && orderDirection !== ('' as NgsSortOrderDirection)
        ? orderDirection
        : // Use default
          this.systemService.systemObj.get('classes_config')[className]!.classConfig.orderDirection;
    this.parseQueryService
      .getClassItems({
        className,
        getAllItems: getAllEntities,
        orderBy,
        orderDirection,
        skipIndex,
        limit,
      })
      .then(
        objects => {
          this.lang = this.ngsOptionsService.get(LOCALE_OPTION_KEY);
          this.setClassProperties(objects, this.systemService.systemObj.get('classes_config'));
          setTimeout(() => {
            this.ngsLoaderService.isLoading$.next(false);
          }, 1000);
        },
        e => {
          this.entities$.error(e);
          throw e;
        },
      );
  };

  /**
   * Update query with current pagination status
   */
  performPagination = (event: PaginationEvent) => {
    this.paginationState$.next({ ...this.paginationState$.value, pageSize: event.pageSize });
    this.performQuery(this.className, false, event);
  };

  /**
   * Build entities according to config
   */
  setClassProperties(objects: Parse.Object[], classesConfig: ClassesConfig) {
    this.columns = [];
    this.searchableProperties = [];
    this.sortableProperties = [];
    this.filterableProperties = [];

    this.entityAttributes$.next([]);
    this.entities$.next([]);

    const filterEmptyValues = (key: string): OperatorFunction<NgsEntities, NgsEntities> => {
      return switchMap((entities: NgsEntities) => {
        return [
          entities.filter(entity => {
            const v = entity[key as keyof NgsEntity];
            if (v != null) {
              switch (v.type) {
                case ClassAttributeType.translatable:
                  return v[this.lang] != null && v[this.lang] !== '';

                default:
                  return v !== '';
              }
            }
            return false;
          }),
        ];
      });
    };

    if (objects && objects.length > 0) {
      const entities = objects.map(obj =>
        this.parseEntityFactory.newInstance(
          obj,
          classesConfig,
          this.systemService.systemObj.get('local_config'),
          this.lang,
        ),
      );
      const classConfig = classesConfig[objects[0]!.className] as ClassConfig;
      const sortedAttrs = Object.keys(classConfig.attributes).sort(
        (key1, key2) => classConfig.attributes[key1]!.index - classConfig.attributes[key2]!.index,
      );
      const visibleKeys: { key: string; displayname: string; subTitles?: string[] }[] = [];

      for (const i in sortedAttrs) {
        const key = sortedAttrs[i] as string;
        if (
          classConfig.attributes[key]?.visible &&
          classConfig.attributes[key]?.type !== ClassAttributeType.container
        ) {
          visibleKeys.push({
            key,
            displayname:
              classConfig.attributes[key]?.labels && classConfig.attributes[key]?.labels.label
                ? (classConfig.attributes[key]?.labels.label as string)
                : key,
          });
          // Look for relations and expand properties to include preview attributes
          const defaultValue = classConfig.attributes[key] as ClassAttribute;
          if (
            defaultValue &&
            defaultValue.type &&
            defaultValue.type === ClassAttributeType.relation &&
            defaultValue.params
          ) {
            const relationClassConfig = classesConfig[defaultValue.params.relationTarget as string] as ClassConfig;
            const relationPreview: any[] = defaultValue.params.relationPreview || [];
            relationPreview.forEach(attr => {
              visibleKeys.push({
                key: `${key}.${attr.key}`,
                displayname: key,
                subTitles:
                  relationClassConfig.attributes[attr.key] &&
                  relationClassConfig.attributes[attr.key]?.labels &&
                  relationClassConfig.attributes[attr.key]?.labels.label
                    ? [relationClassConfig.attributes[attr.key]?.labels.label as string]
                    : [attr.key],
              });
            });
          }
        }
      }

      this.columns = visibleKeys.map(v => {
        return {
          property: v.key,
          displayname: v.displayname,
          index: visibleKeys.indexOf(v),
          subTitles: v.subTitles,
        };
      });

      const filterKeys = visibleKeys.filter(v => {
        return classConfig.attributes[v.key] !== undefined;
      });
      this.searchableProperties = filterKeys.map(v => {
        return { property: v.key, displayname: v.displayname };
      });
      this.sortableProperties = filterKeys.map(v => {
        return { property: v.key, displayname: v.displayname, subTitles: v.subTitles };
      });

      this.filterableProperties = filterKeys.map(v => {
        const fv: NgsFilterableProperty = {
          property: v.key,
          displayname: v.displayname,
          subTitles: v.subTitles,
          type: NgsFilterType.Completable,
          selfComparison: true,
        };
        switch (classConfig.attributes[v.key]?.type) {
          case ClassAttributeType.date:
            Object.assign(fv, { type: NgsFilterType.Date });
            break;

          case ClassAttributeType.relation:
          case ClassAttributeType.pointer:
            {
              const defaultValue = classConfig.attributes[v.key] as ClassAttribute;
              if (defaultValue && defaultValue.params) {
                const relationController = new ParseEntityController(
                  this.systemService,
                  this.parseEntityFactory,
                  this.parseCloudController,
                  this.parseQueryService,
                  this.ngsOptionsService,
                  this.ngsLoaderService,
                  this.ngsDialogService,
                  this.ngsSnackbarService,
                );
                Object.assign(fv, {
                  possibleValues$: relationController.entities$,
                  selfComparison: false,
                });
                relationController.performQuery(defaultValue.params.relationTarget as string);
              } else {
                Object.assign(fv, { possibleValues$: this.entities$ });
              }
            }
            break;

          default:
            Object.assign(fv, {
              possibleValues$: this.entities$.pipe(filterEmptyValues(v.key)),
            });
            break;
        }
        return fv;
      });

      this.entityAttributes$.next(sortedAttrs);
      this.entities$.next(entities);
    }
  }

  getClassObj(className: string, id: NgsId): Promise<ParseEntity> {
    return new Promise<ParseEntity>((resolve, reject) => {
      new Parse.Query(className)
        .equalTo('objectId', id)
        .first()
        .then(object => {
          if (object) {
            resolve(
              this.parseEntityFactory.newInstance(
                object,
                this.systemService.systemObj.get('classes_config'),
                this.systemService.systemObj.get('local_config'),
                this.lang,
              ),
            );
          } else {
            reject(`Error getting class object: ${className} -> ${id}`);
          }
        });
    });
  }

  updateParseEntity(object: Parse.Object, input: ParseEntity): Promise<Parse.Object> {
    return new Promise((resolve, reject) => {
      const proms: Promise<{ key?: string; result: any }>[] = [];
      for (const key in input.classConfig.attributes) {
        const attr = input.classConfig.attributes[key] as ClassAttribute;
        let valid = false;
        switch (key) {
          case 'objectId':
          case 'className':
          case 'displayname':
          case 'createdAt':
          case 'updatedAt':
          case 'ACL':
          case 'acl':
            // Do not save
            break;

          default:
            switch (attr.type) {
              case ClassAttributeType.versioning:
                valid = true;
                break;

              default:
                // Only allow editable attributes.
                // This should move to api and develop further using acl and masterKey
                if (attr.edit) {
                  valid = true;
                }
                break;
            }
            break;
        }
        if (valid) {
          proms.push(
            SetParseEntityValue(
              object,
              key,
              input[key as keyof ParseEntity],
              input.classConfig,
              this.systemService.systemObj.get('local_config').defaultDateFormat,
            ).then(result => {
              return { key, result };
            }),
          );
        }
      }

      const overrides: Promise<{ key?: string; result: any }>[] = [];
      if (input.post) {
        // Run pre save. Should be refactored
        input.obj = object;
        overrides.push(input.post());
      }
      Promise.all(proms).then(
        results => {
          Promise.all(overrides).then(overridesResults => {
            let valid = true;
            for (const i in results) {
              if (!results[i]?.result && !overridesResults.find(v => v.key === results[i]?.key)?.result) {
                valid = false;
                reject(new Error(`Error setting entity value for attribute ${results[i]?.key}`));
                break;
              }
            }
            if (valid) {
              resolve(object);
            }
          }, reject);
        },
        e => {
          reject(e);
        },
      );
    });
  }

  loadObjRelations = (entity: ParseEntity): Promise<ParseEntity> => {
    return new Promise<ParseEntity>((resolve, reject) => {
      const queries = [];

      const getRelatedObjects = (key: keyof ParseEntity, relation: ClassRelationSchema) => {
        return new Promise<RelationQuery>((resolve, reject) => {
          let query: Parse.Query | null = null,
            className = '',
            limit = 500;
          if (relation instanceof Parse.Relation) {
            query = relation.query();
            className = relation.query().className;
          } else if (relation.params.objects) {
            query = new Parse.Query(relation.params.relationTarget as string).containedIn(
              'objectId',
              relation.params.objects.map((o: any) => o.id),
            );
            className = relation.params.relationTarget as string;
            limit = relation.params.objects.length;
          }
          if (query != null) {
            query
              .limit(limit)
              .find()
              .then(
                (relations: Parse.Object[]) =>
                  resolve({
                    key,
                    results:
                      relations.length > 0
                        ? relations.map((o: Parse.Object) =>
                            this.parseEntityFactory.newInstance(
                              o,
                              this.systemService.systemObj.get('classes_config'),
                              this.systemService.systemObj.get('local_config'),
                              this.lang,
                            ),
                          )
                        : [],
                  }),
                reject,
              );
          } else {
            reject(new Error(`Relation query is invalid for key: ${key}`));
          }
        });
      };

      for (const key in entity.classConfig.attributes) {
        const attr = entity.classConfig.attributes[key] as ClassAttribute;
        if (attr.type === ClassAttributeType.relation) {
          const relation = entity.obj.get(key);
          if (relation) {
            queries.push(getRelatedObjects(key as keyof ParseEntity, relation));
          }
        }
      }

      Promise.all(queries).then(results => {
        results.forEach(res => {
          const attr = entity.classConfig.attributes[res.key] as ClassAttribute;
          const r: any = res.results;
          entity[res.key as keyof ParseEntity] = attr.params.relationLimit === 1 ? r[0] : r;
        });
        resolve(entity);
      }, reject);
    });
  };

  exportExl(className: string, attributes: KeysWithValue<ClassAttribute>, id?: NgsId | NgsIds) {
    this.parseCloudController
      .runCloudFunction<
        {
          className: string;
          attributes: KeysWithValue<ClassAttribute>;
          id?: NgsId | NgsIds;
          lang: string;
        },
        { id: string }
      >(MonitoringCloudFunctions.ExlExportEntities, { className, attributes, id, lang: this.lang })
      .then(res => this.parseCloudController.downloadFile(res.result.id).catch(this.onError), this.onError);
  }

  importExl(className: string) {
    this.parseCloudController.uploadParseFiles({ accept: '.xls,.xlsx' }).then((files: any) => {
      this.parseCloudController.runCloudFunction(MonitoringCloudFunctions.ExlImportEntities, { className, files }).then(
        res => {
          this.ngsLoaderService.isLoading$.next(true);
          // Enable systemObj live query
          this.systemService.blockSystemObjUpdates = false;
          this.systemService.fetchSystemObj();
          this.onCloudFunctionCompleted(res);
        },
        e => {
          this.ngsLoaderService.isLoading$.next(true);
          // Enable systemObj live query
          this.systemService.blockSystemObjUpdates = false;
          this.systemService.fetchSystemObj();
          this.onError(e);
        },
      );
    }, this.onError);
  }

  syncSx(className: string) {
    this.parseCloudController.runCloudFunction(MonitoringCloudFunctions.SyncSurvey, { className }).then(
      res => this.onCloudFunctionCompleted(res),
      e => this.onError(e),
    );
  }

  onCloudFunctionCompleted = (result: CloudFunctionResult<any>) => {
    if (result.actions && result.actions.length > 0) {
      result.actions.forEach(action => {
        action.onClientFunctionComplete.forEach(clientAction => {
          switch (clientAction) {
            case CloudFunctionResultClientActions.perform_query:
              this.performQuery(this.className);
              break;
          }
        });
      });
    }
  };

  getImportTemplate(className: string) {
    this.parseCloudController
      .runCloudFunction<
        { className: string; lang: string },
        { id: string }
      >(MonitoringCloudFunctions.ExlGetImportTemplate, { className, lang: this.lang })
      .then(
        res => {
          this.parseCloudController.downloadFile(res.result.id).catch(this.onError);
        },
        e => {
          this.onError(e, null, 2000);
        },
      );
  }

  getEntityValue = (entity: NgsEntity, property: string): Observable<string> => {
    const parseEntity = entity as ParseEntity;
    if (parseEntity.getEntityValue) {
      return parseEntity.getEntityValue(property, this.lang);
    } else {
      // TODO
      return of('MISSING');
    }
  };

  loadClass = (className: string, ids: NgsIds): Promise<any> => {
    return new Promise<ParseEntities>((resolve, reject) => {
      new Parse.Query(className)
        .limit(ids.length || 500)
        .containedIn('objectId', ids)
        .find()
        .then(objects => {
          if (objects && objects.length > 0) {
            const entities: ParseEntities = [];
            objects.forEach(obj => {
              entities.push(
                this.parseEntityFactory.newInstance(
                  obj,
                  this.systemService.systemObj.get('classes_config'),
                  this.systemService.systemObj.get('local_config'),
                  this.lang,
                ),
              );
            });
            resolve(entities);
          } else {
            resolve([]);
          }
        }, reject);
    });
  };

  onError = (error: Parse.Error | Error, reject?: ((reason?: any) => void) | null, toastDuration?: number) => {
    this.ngsLoaderService.isLoading$.next(false);
    console.error(error);
    let args = {};
    if (error.message.indexOf('<$args>') >= 0) {
      // Parse error string args
      args = JSON.parse(
        error.message.substring(error.message.indexOf('<$args>') + 7, error.message.indexOf('</$args>')),
      );
      error.message = error.message.substring(0, error.message.indexOf('<$args>'));
    }

    this.ngsSnackbarService.open(error.message, undefined, { duration: toastDuration });
    if (reject) {
      reject({ ...error, args });
    }
  };

  // Interface

  constructEntity(input: KeysWithValue<any>, params?: any): Observable<ParseEntity> {
    const className: any = params ? params.className : input ? input['className'] : null;
    const classesConfig: ClassesConfig = this.systemService.systemObj.get('classes_config');
    const object = new Parse.Object(className);
    setObjectACLPermissions(new Parse.ACL(), object, classesConfig[className]!.permissions);
    return of(
      this.parseEntityFactory.newInstance(
        object,
        classesConfig,
        this.systemService.systemObj.get('local_config'),
        this.lang,
        input,
      ),
    );
  }
  get(id: NgsId, params: any): Observable<ParseEntity> {
    this.ngsLoaderService.isLoading$.next(true);
    let obs: Observable<ParseEntity>;
    if (id && id !== 'undefined') {
      obs = from(this.getClassObj(params['className'], id));
    } else {
      obs = from(this.constructEntity({}, params));
    }
    obs.pipe(catchError(e => of(this.onError(e)))).subscribe(entity => {
      if (entity) {
        this.entities$.next([entity]);
        this.ngsLoaderService.isLoading$.next(false);
      }
    });
    if (params.loadRelations) {
      return obs.pipe(switchMap(this.loadObjRelations));
    } else {
      return obs;
    }
  }
  indexRoute(): string {
    return '/';
  }
  delete(ids: NgsOneOrMultiple<NgsId>, params?: any) {
    if (ids) {
      ids = Array.isArray(ids) ? ids : [ids];
      this.parseCloudController
        .runCloudFunction<{ className: string; ids: NgsId[] }, { deletedCount: number }>(
          MonitoringCloudFunctions.DeleteClassObjects,
          {
            className: params.className || this.className,
            ids,
          },
        )
        .then(res => {
          this.openSnackbar(res.result.deletedCount + ' Objects deleted');
          this.performQuery(params.className);
        }, this.onError);
    } else {
      this.openSnackbar('Object id is missing');
    }
  }
  deleteAll(className: string) {
    this.parseCloudController
      .runCloudFunction<
        { className: string },
        { deletedCount: number }
      >(MonitoringCloudFunctions.DeleteAllClassObjects, { className })
      .then(res => {
        this.openSnackbar(res.result.deletedCount + ' Objects deleted');
        this.performQuery(className);
      }, this.onError);
  }
  store(entity: NgsOneOrMultiple<ParseEntity>): Observable<ParseEntity> {
    return from(
      new Promise<ParseEntity>((resolve, reject) => {
        if (Array.isArray(entity)) {
          this.onError(new Error('Entities array is not supported'));
        } else {
          this.ngsLoaderService.isLoading$.next(true);
          this.updateParseEntity(entity.obj, entity).then(
            obj => {
              obj.save().then(
                savedObj => {
                  this.ngsLoaderService.isLoading$.next(false);
                  const entity = this.parseEntityFactory.newInstance(
                    savedObj,
                    this.systemService.systemObj.get('classes_config'),
                    this.systemService.systemObj.get('local_config'),
                    this.lang,
                  );
                  this.openSnackbar(`${entity.displayname} created`);
                  resolve(entity);
                },
                e => this.onError(e, reject),
              );
            },
            e => this.onError(e, reject),
          );
        }
      }),
    );
  }
  update(input: NgsOneOrMultiple<ParseEntity>): Observable<ParseEntity> {
    return from(
      new Promise<ParseEntity>((resolve, reject) => {
        if (Array.isArray(input)) {
          this.onError(new Error('Entities array is not supported'));
        } else {
          this.ngsLoaderService.isLoading$.next(true);
          new Parse.Query(input.className)
            .equalTo('objectId', input.id)
            .first()
            .then(
              object => {
                if (object) {
                  // Update object
                  this.updateParseEntity(object, input).then(
                    res => {
                      res.save().then(
                        savedObj => {
                          this.ngsLoaderService.isLoading$.next(false);
                          const entity = this.parseEntityFactory.newInstance(
                            savedObj,
                            this.systemService.systemObj.get('classes_config'),
                            this.systemService.systemObj.get('local_config'),
                            this.lang,
                          );
                          this.openSnackbar(`${entity.displayname} updated`);
                          resolve(entity);
                        },
                        e => {
                          this.onError(e || new Error('Error saving object'), reject);
                        },
                      );
                    },
                    e => {
                      this.onError(e, reject);
                    },
                  );
                } else {
                  this.openSnackbar('Entry was not found');
                  this.onError(new Parse.Error(Parse.Error.OBJECT_NOT_FOUND, 'Entry was not found'), reject);
                }
              },
              e => {
                this.openSnackbar(e.message || 'Failed to retrieve object');
                this.onError(e, reject);
              },
            );
        }
      }),
    );
  }

  // General

  openSnackbar = (message: string, duration = 1500) => {
    this.ngsSnackbarService.open(message, undefined, { duration });
  };

  // No implemented

  loadAll() {
    throw new Error('Method not implemented.');
  }
  load(id: any) {
    throw new Error('Method not implemented.');
  }
  upsert(entities: NgsOneOrMultiple<ParseEntity>) {
    throw new Error('Method not implemented.');
  }
  reset() {
    throw new Error('Method not implemented.');
  }
  getSnapshot(id: NgsId): ParseEntity {
    throw new Error('Method not implemented.');
  }
  setRouteParameters(params: NgsHttpRouteParameters): INgsEntityController<ParseEntity> {
    throw new Error('Method not implemented.');
  }
  onDirty() {
    throw new Error('Method not implemented.');
  }
  setDirty(state: boolean) {
    throw new Error('Method not implemented.');
  }
}
