import { Injectable } from '@angular/core';
import moment from 'moment';
import type { NgsCompletable, NgsId } from '@syspons/ngs-common/models';
import { type NgsEntity, roundTo, type NgsEntityFormatValuesParams } from '@syspons/models';

import Parse from 'parse';
import { Observable, of } from 'rxjs';
import {
  type ClassesConfig,
  type ClassAttribute,
  ClassAttributeType,
  type ClassConfig,
  type LocaleConfig,
  GetEntityFormattedValue,
  type ClassRelationObjectSchema,
  type UserObject,
  type ClassTranslatableSchema,
  generateRandomPassword,
  ClassAttributeNumberType,
} from '@syspons/monitoring-api-common';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ParseEntityFactory {
  newInstance(
    obj: Parse.Object,
    classesConfig: ClassesConfig,
    localeConfig: LocaleConfig,
    lang: string,
    input?: any,
  ): ParseEntity {
    const getRelation = (objects: ClassRelationObjectSchema[]): ClassRelationObjectSchema[] => {
      return objects.map((o: any) => {
        // Displayname
        const displayname = o.displayname
          ? o.displayname.type && o.displayname.type === ClassAttributeType.translatable
            ? o.displayname[lang || 'en']
            : o.displayname
          : o.defaultAttribute && o.defaultAttribute.type === ClassAttributeType.translatable
            ? o.defaultAttribute[lang || 'en']
            : o.defaultAttribute;
        // Preview
        if (o.preview) {
          Object.keys(o.preview).forEach(k => {
            if (o.preview[k] instanceof Date) {
              o.preview[k] = moment(o.preview[k]).format(
                classesConfig[o.className]?.attributes[k]?.params.datePickerDateFormat ||
                  localeConfig.defaultDateFormat,
              );
            }
          });
        }
        return {
          type: 'relation',
          id: o.id,
          className: o.className,
          displayname: displayname === '' ? o.id : displayname,
          defaultAttribute: o.defaultAttribute,
          ...o.preview,
        };
      });
    };

    const getValue = (attr: ClassAttribute, input: Parse.Object | any) => {
      let value;
      switch (attr.key) {
        case 'className':
          value = input[attr.key];
          break;

        case 'ACL':
          value = input.getACL ? input.getACL() : null;
          break;

        case 'id':
        case 'objectId':
          value = input.id || input.objectId;
          break;

        default:
          value = input.get ? input.get(attr.key) : input[attr.key];
          break;
      }

      if (value) {
        switch (attr.type) {
          case ClassAttributeType.date:
            value = moment(value, attr.params.datePickerDateFormat || localeConfig.defaultDateFormat);
            break;

          case ClassAttributeType.relation:
          case ClassAttributeType.pointer:
            if (attr.params) {
              value = getRelation(
                value.objects
                  ? // Pointer
                    value.objects
                  : // Relation
                    value.params && value.params.objects
                    ? value.params.objects
                    : Array.isArray(value)
                      ? value
                      : [value],
              );
              if (attr.params.relationLimit === 1 && value.length === 1) {
                value = value[0];
              }
            } else {
              // Parse Relation.
              if (Array.isArray(value)) {
                value = value.map(v =>
                  v != null
                    ? {
                        type: 'relation',
                        className: v.className,
                        id: v.id,
                        obj: v.obj,
                      }
                    : v,
                );
              } else {
                value = {
                  type: 'relation',
                  className: value.className,
                  id: value.id,
                  obj: value.obj,
                };
              }
            }
            break;

          case ClassAttributeType.acl:
            {
              // TODO: ACL model
              // Null = public read + write
              if (value) {
                const acl = [];
                if (value.getPublicReadAccess()) {
                  acl.push({
                    permission: 'public_read',
                    displayname: 'Public Read',
                    type: 'acl',
                  });
                }
                if (value.getPublicWriteAccess()) {
                  acl.push({
                    permission: 'public_write',
                    displayname: 'Public Write',
                    type: 'acl',
                  });
                }
                for (const key in value.permissionsById) {
                  if (key !== '*') {
                    if (value.permissionsById[key].read) {
                      acl.push({
                        id: key,
                        permission: 'read',
                        displayname: key + ' Read',
                        type: 'acl',
                      });
                    }
                    if (value.permissionsById[key].write) {
                      acl.push({
                        id: key,
                        permission: 'write',
                        displayname: key + ' Write',
                        type: 'acl',
                      });
                    }
                  }
                }
                value = acl;
              }
            }
            break;
        }
      }

      return value;
    };

    const classConfig = classesConfig[obj.className] as ClassConfig;
    const defaultAttribute = obj.get(classConfig.classConfig.defaultAttribute);
    let displayname = input ? input.name : null;

    // Strip a parse object and return clean json format
    // const keys = Object.keys(classesConfig[obj.className].attributes);
    const attrs = {
      id: obj.id,
      className: obj.className,
      obj: obj,
    };
    for (const key in classesConfig[obj.className]?.attributes) {
      const attr = classesConfig[obj.className]!.attributes[key] as ClassAttribute;
      let value = getValue(attr, input || obj);
      if (value instanceof Parse.Object) {
        value = value.id;
      }
      Object.assign(attrs, { [attr.key]: value });
    }

    displayname = defaultAttribute;
    if (defaultAttribute && defaultAttribute.type === ClassAttributeType.translatable) {
      displayname = defaultAttribute[lang || 'en'];
    }
    if (!displayname && classConfig.classConfig.defaultPlaceholder) {
      displayname = classConfig.classConfig.defaultPlaceholder.key;
      if (classConfig.classConfig.defaultPlaceholder.type === 'attribute') {
        displayname =
          classConfig.classConfig.defaultPlaceholder.key === 'objectId'
            ? obj.id
            : obj.get(classConfig.classConfig.defaultPlaceholder.key);
      }
    }

    return this.createEntity(classConfig, localeConfig, lang, attrs, displayname);
  }

  createEntity = (
    classConfig: ClassConfig,
    localeConfig: LocaleConfig,
    lang: string,
    attrs: any,
    displayname: string,
  ): ParseEntity => {
    return new ParseEntity(classConfig, lang, localeConfig.defaultDateFormat).deserialize(attrs, displayname);
  };
}

export class ParseEntity implements NgsEntity, NgsCompletable {
  obj: Parse.Object;

  id: NgsId;
  createdAt: Date;
  updatedAt: Date;
  ACL: Parse.ACL;

  className: string;
  displayname: string;

  constructor(
    public classConfig: ClassConfig,
    public lang: string,
    public defaultDateFormat: string,
  ) {}

  deserialize(input: any, displayname?: string): this {
    return Object.assign(this, input, {
      displayname: (displayname || input.name)?.toString(),
      id: input.id || input.objectId,
    });
  }

  formatValue = (key: string, value: any, params?: NgsEntityFormatValuesParams): any => {
    return GetEntityFormattedValue(
      value,
      this.classConfig && this.classConfig.attributes[key] ? this.classConfig.attributes[key]?.type : undefined,
      this.lang,
      this.classConfig && this.classConfig.attributes[key]
        ? this.classConfig.attributes[key]?.params.datePickerDateFormat || this.defaultDateFormat
        : this.defaultDateFormat,
      params,
    );
  };
  getEntityValue = (key: string, locale?: string): Observable<any> => {
    const getValue = (type: string, v: any) => {
      if (v != null) {
        if (type) {
          switch (type.toLowerCase()) {
            case ClassAttributeType.translatable:
              return v[locale || this.lang || 'en'];

            case ClassAttributeType.relation:
            case ClassAttributeType.pointer:
              if (Array.isArray(v)) {
                const relationObjects: ClassRelationObjectSchema[] = v;
                v = relationObjects.map(obj => {
                  let displayname = obj.defaultAttribute || obj.id;
                  if (obj.defaultAttribute && (obj.defaultAttribute as ClassTranslatableSchema).type != null) {
                    displayname = getValue((obj.defaultAttribute as ClassTranslatableSchema).type, displayname);
                  }
                  if (!displayname || displayname === '') {
                    displayname = obj.id;
                  }
                  return displayname;
                });
                let res = v[0];
                v.forEach((a: any, i: number) => {
                  if (i > 0) {
                    res += '<br/>' + a;
                  }
                });
                v = res;
              } else if (v.displayname) {
                v = getValue(v.displayname.type, v.displayname);
              }
              return v;

            case ClassAttributeType.date:
              if (attr.params.datePickerDateFormat) {
                v = moment(value, attr.params.datePickerDateFormat).format(attr.params.datePickerDateFormat);
              } else if (this.defaultDateFormat) {
                v = moment(value, this.defaultDateFormat).format(this.defaultDateFormat);
              }
              if (v === 'Invalid date') {
                v = value;
              }
              return v;

            case ClassAttributeType.number:
              if (attr.params.numberType === ClassAttributeNumberType.integer) {
                return parseInt(v);
              } else {
                v = roundTo(v, attr.params.floatDecimals || 1) + '';
                return v.replace('.', ',');
              }

            default:
              return v;
          }
        } else {
          return v;
        }
      } else {
        return v;
      }
    };

    let value: any = this[key as keyof ParseEntity];
    const attr = this.classConfig.attributes[key] as ClassAttribute;

    if (attr) {
      value = getValue(attr.type, value);
    }
    // relationPreview
    else if (!attr && key.indexOf('.') > 0) {
      // Search in relations
      const rel = key.substring(0, key.indexOf('.'));
      key = key.substring(key.indexOf('.') + 1, key.length);
      let relation: any[] = (this[rel as keyof ParseEntity] as any) || [];
      if (!Array.isArray(relation)) {
        relation = [relation];
      }
      value = [];
      relation.map((o: any) => {
        if (o[key]) {
          value.push(getValue(o[key].type, o[key]));
        }
      });
      let res = value[0];
      value.forEach((a: any, i: number) => {
        if (i > 0) {
          res += '<br/>' + a;
        }
      });
      value = res;
    }

    return value != null ? of(value) : of('');
  };

  post = (): Promise<{ key?: string; result: any }> => {
    return new Promise((resolve, reject) => {
      let result: any;
      if (this.className === '_User') {
        const userObj = this.obj as Parse.User<UserObject>;
        if (!userObj.get('username')) {
          result = userObj.set(
            'username',
            `${this.obj.get('first_name')}.${userObj.get('last_name')}<$>${new Date().getTime()}</$>`,
          );
        }
        if (!userObj.get('password')) {
          const password = generateRandomPassword(this.classConfig.additionalConfig?.params.passwordPolicy);
          userObj.set('temp_password', password);
          result = userObj.set('password', password);
        }
      }
      resolve({ result });
    });
  };
}

export declare type ParseEntities = ParseEntity[];
