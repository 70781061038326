import { Injectable } from '@angular/core';
import type { ClassConfig, ClassRelationSchema, LocaleConfig, UserObject } from '@syspons/monitoring-api-common';

import { ParseEntity, ParseEntityFactory } from '../parseEntity/parseEntity.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UserFactory extends ParseEntityFactory {
  override createEntity = (
    classConfig: ClassConfig,
    localeConfig: LocaleConfig,
    lang: string,
    attrs: any,
    displayname: string,
  ) => {
    return new UserEntity(classConfig, lang, localeConfig.defaultDateFormat).deserialize(attrs, displayname);
  };
}

export class UserEntity extends ParseEntity {
  override obj: Parse.User<UserObject>;
  first_name: string;
  last_name: string;
  username: string;
  email: string;

  roles: ClassRelationSchema;

  override deserialize(input: ParseEntity, displayname?: string | undefined): this {
    const attrs: any = {};
    const object = input.obj as Parse.User<UserObject>;
    for (const key in object.attributes) {
      attrs[key] = input.obj.get(key);
      if (attrs[key] instanceof Date) {
        attrs[key] = moment(attrs[key]).format(this.defaultDateFormat);
      }
    }
    return Object.assign(this, input, {
      displayname: displayname?.toString(),
      id: input.id,
      ...attrs,
    });
  }
}

export declare type Users = UserEntity[];
