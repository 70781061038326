import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgsSnackbarModule } from '@syspons/ngs-snackbar';
import { NgsAlternativeTranslationModule, NgsTranslationModule } from '@syspons/ngs-translation';

import { NgsDialogModule } from '@syspons/ngs-dialog';
import { NgsLoggerModule } from '@syspons/ngs-logger';
import { NgsStorageModule } from '@syspons/ngs-storage';
import { NgsLocaleModule } from '@syspons/ngs-locale';
import { NgsErrorHandlerModule } from '@syspons/ngs-error-handler';

import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxNativeDatetimeModule } from '@ng-matero/extensions/core';
import { MtxMomentDatetimeModule } from '@ng-matero/extensions-moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { PermissionModule } from './controllers/permission/permission.module';
import { FeaturesModule } from './features/features.module';
import { environment } from '../environments/environment';
import { ParseSystemModule } from './controllers/parseSystem/parseSystem.module';
import { PagesModule } from './pages/pages.module';

const languages = ['en', 'de'];

@NgModule({
  imports: [
    CommonModule,
    NgsTranslationModule.forRoot({
      defaultLanguage: languages[0] as string,
    }),
    NgsAlternativeTranslationModule.forRoot({
      defaultLanguage: languages[0] as string,
    }),
    NgsStorageModule.forRoot({
      expires: 60,
    }),
    NgsLocaleModule.forRoot({
      defaultLanguage: languages[0],
      languages: languages,
    }),
    NgsLoggerModule.forRoot({
      apiUrl: '',
      level: environment.logLevel,
      serverLevel: environment.serverLogLevel,
      interval: 10000,
    }),
    NgsErrorHandlerModule.forRoot(),
    NgsDialogModule,
    NgsSnackbarModule,

    ParseSystemModule,
    PermissionModule,
    FeaturesModule,
    PagesModule,

    // TODO: move dateTime to ngs-core
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MtxDatetimepickerModule,
    MtxNativeDatetimeModule,
    MtxMomentDatetimeModule,
  ],
})
export class CoreModule {}
