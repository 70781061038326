import Parse from 'parse';
import { Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParseCloudController } from '../parseCloud/parseCloud.Controller';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MonitoringSchemaConfig, type SystemObject } from '@syspons/monitoring-api-common';
import moment from 'moment';

export interface ServerConfig {
  mode: string;
  appId: string;
  clientKey: string;
  serverURL: string;
}

export type KeysWithValue<T> = Record<string, T>;
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export default class ParseSystemService {
  private systemQuery: Parse.Query<Parse.Object<SystemObject>>;
  private _systemObjFetched = new ReplaySubject<boolean>();

  public systemObj: Parse.Object<SystemObject>;
  public serverConfig: ServerConfig;

  public error$ = new ReplaySubject<Error>();
  public systemObjFetched$: Observable<boolean>;
  public blockSystemObjUpdates: boolean;

  constructor(private parseCloudController: ParseCloudController) {
    this.systemObjFetched$ = this._systemObjFetched.asObservable().pipe(untilDestroyed(this));
  }

  init = (serverConfig: ServerConfig) => {
    this.serverConfig = serverConfig;

    this.systemQuery = new Parse.Query<Parse.Object<SystemObject>>(MonitoringSchemaConfig.system.className).equalTo(
      'name',
      MonitoringSchemaConfig.system.systemObjName,
    );
    this.systemQuery.subscribe().then(subscription => {
      this.systemQuery.first().then(systemObj => {
        if (systemObj) {
          this.setSystemObj(systemObj);
          console.log(`System object loaded: ${systemObj.id} / ${systemObj.get('name')}`);
        } else {
          console.log(
            `SystemController: System object was not found: ${MonitoringSchemaConfig.system.className} -> ${MonitoringSchemaConfig.system.systemObjName}`,
          );
        }
      }, this.OnError);
      subscription.on('open', () => {
        console.log('SystemController. System subscription opened');
      });
      subscription.on('update', systemObj => {
        if (!this.blockSystemObjUpdates) {
          this.setSystemObj(systemObj as Parse.Object<SystemObject>);
        }
      });
    }, this.OnError);
  };

  OnError = (e: Error | string) => {
    console.log(e instanceof Error ? e.message : e);
    this.error$.next(e instanceof Error ? e : new Error(e));
  };

  private setSystemObj = (systemObj: Parse.Object<SystemObject>) => {
    if (systemObj) {
      this.systemObj = systemObj;

      const localeConfig = systemObj.get('local_config');
      moment.locale(localeConfig.defaultLocale.toString());
      moment.defaultFormat = localeConfig.defaultDateFormat;

      this._systemObjFetched.next(true);
    }
  };

  public fetchSystemObj = () => {
    this.systemObj.fetch().then(this.setSystemObj, this.OnError);
  };

  public saveSystemObj = (): Promise<Parse.Object<SystemObject>> => {
    return this.systemObj.save();
  };
}
