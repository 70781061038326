export const environment = {
  production: false,
  appName: 'monitoring-test',
  version: require('../../package.json').version + '-dev',
  outDir: require('../../angular.json').projects.frontend.architect.build.options.deployUrl,
  keycloak: {
    realm: 'keycloak-testing',
    url: 'https://keycloak-update.syspons-dev.com/auth/',
    clientId: 'monitoring-web',
  },
  logLevel: 600,
  serverLogLevel: 600,
  logoUrl: '/assets/images/giz.png',
};

// export const environment = {
//   production: false,
//   appName: 'monitoring-meg',
//   version: require('../../package.json').version + '-dev',
//   outDir: require('../../angular.json').projects.frontend.architect.build.options.deployUrl,
//   keycloak: {
//     realm: 'monitoring-meg',
//     url: 'https://login.syspons.com/auth/',
//     clientId: 'monitoring-web',
//   },
//   logLevel: 600,
//   serverLogLevel: 600,
//   logoUrl: '/assets/images/giz.png',
// };
