import { Component, type OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MonitoringCloudFunctions } from '@syspons/monitoring-api-common';
import { type INgsTableauDashboardConfig, NgsTableauDashboardConfigToolbar } from '@syspons/ngs-tableau-dashboard';
import { combineLatest, debounceTime, distinctUntilChanged, from } from 'rxjs';
import { ParseCloudController } from 'src/app/controllers/parseCloud/parseCloud.Controller';
import ParseSystemService from 'src/app/controllers/parseSystem/parseSystem.service';

@UntilDestroy()
@Component({
  selector: 'app-tableau-dashboard',
  template: `<ngs-tableau-dashboard [config]="config"></ngs-tableau-dashboard>`,
})
export class TableauDashboardComponent implements OnInit {
  config: Partial<INgsTableauDashboardConfig> = {};

  constructor(
    private systemService: ParseSystemService,
    private cloudController: ParseCloudController,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.route.params,
      this.systemService.systemObjFetched$,
      this.cloudController.runCloudFunction<any, string>(MonitoringCloudFunctions.TableauGetJwt, {}),
    ])
      .pipe(debounceTime(1000), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(([params, systemObjFetched, jwt]) => {
        if (params && systemObjFetched && jwt) {
          const tableauConfig = this.systemService.systemObj.get('tableau_config') || {
            dashboards: [],
          };
          const current = tableauConfig.dashboards.find(dashboard => dashboard.id === params['id']);
          if (current) {
            this.config = {
              url: current.url,
              token: jwt.result,
              toolbar: NgsTableauDashboardConfigToolbar.Bottom,
            };
          }
        }
      });
  }
}
